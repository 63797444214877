import './applications.scss';
import React, {useCallback, useEffect, useMemo, useState, useRef} from 'react';
import {apiSfa} from "../../services/api/sfa";
import {apiEmpower} from "../../services/api/empower";
import Main from "../../modules/main/main";
import LinearProgress from '@mui/material/LinearProgress';
import {useNavigate} from "react-router-dom";
import usePermissions from "../../services/auth/usePermissions";
import useUser from "../../services/auth/useUser";
import {AgGridReact} from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';


function Applications(props) {
    const sfaGridRef = useRef();
    const empowerGridRef = useRef();
    const navigate = useNavigate();
    const user = useUser();
    const [userCan] = usePermissions();
    const [sfaData, setSfaData] = useState([]);
    const [tablePageSize, setTablePageSize] = useState(10);
    const [enableEmpowerApplication, setEnableEmpowerApplication] = useState(false);
    const [enableEditEmpowerApplication, setEnableEditEmpowerApplication] = useState(false);
    const [enableSfaApplication, setEnableSfaApplication] = useState(false);
    const [enableEditSfaApplication, setEnableEditSfaApplication] = useState(false);
    const [empowerData, setEmpowerData] = useState([]);
    const [loading, setLoading] = useState(true);

    const sfaColumnDefs = useMemo(() => {
            return [
                {
                    field: 'id',
                    headerName: 'ID #',
                    sort: 'asc'
                },
                {
                    headerName: 'Student Name',
                    valueGetter: params => {
                        const student_info = params.data.sfa_student_info;
                        if (student_info) {
                            return student_info.studentFirstName + ' ' + student_info.studentLastName
                        }

                        return null
                    },
                    cellRenderer: params => {
                        if (params.value) {
                            return params.value
                        }

                        return <em>No Student Name Set</em>
                    }
                },
                {
                    headerName: 'City',
                    valueGetter: params => {
                        const student_info = params.data.sfa_student_info;
                        if (student_info) {
                            return student_info.city
                        }

                        return null
                    },
                    cellRenderer: params => {
                        if (params.value) {
                            return params.value
                        }

                        return <em>No City</em>
                    },
                    hide: !userCan('review.sfa')
                },
                {
                    headerName: 'State',
                    valueGetter: params => {
                        const student_info = params.data.sfa_student_info;
                        if (student_info) {
                            return student_info.state
                        }

                        return null
                    },
                    cellRenderer: params => {
                        if (params.value) {
                            return params.value
                        }

                        return <em>No State</em>
                    },
                    hide: !userCan('review.sfa')
                },
                {
                    headerName: 'Progress',
                    valueGetter: params => params.data.percent_complete,
                    cellRenderer: params => <RenderProgressBar row={params.data}/>,
                    filter: 'agNumberColumnFilter',
                },
                {
                    headerName: "Reviewed By",
                    valueGetter: params => params.data.sfa_review?.reviewed_by,
                },
                {
                    headerName: "Reviewed Status",
                    valueGetter: params => params.data.sfa_review?.status,
                },
                {
                    headerName: 'Action / Status',
                    valueGetter: params => sfaApplicationStatus(params.data, true),
                    cellRenderer: params => {
                        return <div className="text-center">{sfaApplicationStatus(params.data)}</div>
                    },
                    filter: false,
                    wrapText: true,
                    autoHeight: true,
                    flex: 1,
                    minWidth: 250
                },
            ]
        });
    const empowerColumnDefs = useMemo(() =>{

        return [
            {
                field: 'id',
                headerName: 'ID #',
                sort: 'asc'
            },
            {
                headerName: 'Teacher Name',
                valueGetter: params => {
                    const teacher_info = params.data.empower_contact_info;
                    if (teacher_info) {
                        return teacher_info.teacherFirstName + ' ' + teacher_info.teacherLastName
                    }

                    return null
                },
                cellRenderer: params => {
                    if (params.value) {
                        return params.value
                    }

                    return <em>No Teacher Name Set</em>
                }
            },
            {
                headerName: 'City',
                valueGetter: params => {
                    const teacher_info = params.data.empower_contact_info;
                    if (teacher_info) {
                        return teacher_info.city
                    }

                    return null
                },
                cellRenderer: params => {
                    if (params.value) {
                        return params.value
                    }

                    return <em>No City</em>
                },
                hide: !userCan('review.empower')
            },
            {
                headerName: 'State',
                valueGetter: params => {
                    const teacher_info = params.data.empower_contact_info;
                    if (teacher_info) {
                        return teacher_info.state
                    }

                    return null
                },
                cellRenderer: params => {
                        if (params.value) {
                            return params.value
                        }

                        return <em>No State</em>
                },
                hide: !userCan('review.empower')
            },
            {
                headerName: 'Progress',
                valueGetter: params => params.data.percent_complete,
                cellRenderer: params => <RenderProgressBar row={params.data}/>,
                filter: 'agNumberColumnFilter',
            },
            {
                headerName: "Reviewed By",
                valueGetter: params => params.data.empower_review?.reviewed_by,
            },
            {
                headerName: "Reviewed Status",
                valueGetter: params => params.data.empower_review?.status,
            },
            {
                headerName: 'Action / Status',
                valueGetter: params => empowerApplicationStatus(params.data, true),
                cellRenderer: params => {
                    return <div className="text-center">{empowerApplicationStatus(params.data)}</div>
                },
                filter: false,
                wrapText: true,
                autoHeight: true,
                flex: 1,
                minWidth: 250
            },
        ]
    });
    const sfaDefaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 150,
            filter: true,
            menuTabs: ['filterMenuTab'],
            floatingFilter: true,
            sortable: true,
            resizable: true,
        };
    }, []);
    const fetchApplications = async () => {
        const promises = [];
        if (userCan('applications.empower')) {
            promises.push(fetchEmpower())
        }
        if (userCan('applications.sfa')) {
            promises.push(fetchSfa())
        }

        return promises
    }
    const fetchSfa = async () => {
        const sfaAllPromise = apiSfa.getAll();
        const sfaSettingsPromise = apiSfa.getSettings();
        return await Promise.all([sfaAllPromise, sfaSettingsPromise]).then(([sfas, settings]) => {
            setSfaData(sfas);
            setEnableSfaApplication(settings.enable_sfa_application)
            setEnableEditSfaApplication(settings.enable_edit_sfa_application)
        })
    }
    const fetchEmpower = async () => {
        const empowerAllPromise = apiEmpower.getAll();
        const empowerSettingsPromise = apiEmpower.getSettings();
        return await Promise.all([empowerAllPromise, empowerSettingsPromise]).then(([empowers, settings]) => {
            setEmpowerData(empowers);
            setEnableEmpowerApplication(settings.enable_empower_application)
            setEnableEditEmpowerApplication(settings.enable_empower_application)
        })
    }
    const RenderProgressBar = ({row}) => {

        return (
            <div className="col-12 d-flex align-items-center">
                <div className="col-9 float-left">
                    <LinearProgress variant="determinate" color="warning" value={row.percent_complete}/>
                </div>
                <div className="col-3 float-left">
                    {row.percent_complete}%
                </div>
            </div>
        )
    }
    const editSfaButton = (row, e) => {
        e.preventDefault();
        navigate('/sfa/' + row.id)
    }
    const editEmpowerButton = (row, e) => {
        e.preventDefault();
        navigate('/empower/' + row.id)
    }
    const reviewEmpowerButton = (row, e) => {
        e.preventDefault();
        navigate('/empower-review/' + row.id)
    }
    const reviewSfaButton = (row, e) => {
        e.preventDefault();
        navigate('/sfa-review/' + row.id)
    }
    const empowerApplicationStatus = (row) => {
        if (row.user_id === user.id) {
            if (row.complete === 1) {
                if (row.empower_review !== null) {
                    return row.empower_review.status !== "" ? row.empower_review.status : "In Review";
                }
                return "In Review"
            }

            if (enableEditEmpowerApplication) {
                return <button className="btn btn-block btn-primary" onClick={(e) => editEmpowerButton(row, e)} id={row.ID}>Edit</button>
            }

            return "This application cannot be edited anymore."

        }

        if (row.complete === 1 && (row.empower_review?.status !== "rejected" && row.empower_review?.status !== "approved")) {
            if (userCan('review.empower')) {
                return <button className="btn btn-block btn-primary" onClick={(e) => reviewEmpowerButton(row, e)}
                               id={row.ID}>Review</button>
            }
        }
        else {
            return "Application hasn't been submitted"
        }
    }
    const sfaApplicationStatus = (row, textOnly = false) => {
        if (row.user_id === user.id) {
            if (row.complete === 1) {
                if (row.sfa_review !== null) {
                    return row.sfa_review.status !== "" ? row.sfa_review.status : "In Review";
                }
                return "In Review"
            }

            if (enableEditSfaApplication) {
                return <button className="btn btn-block btn-primary" onClick={(e) => editSfaButton(row, e)} id={row.ID}>Edit</button>
            }

            return "This application cannot be edited anymore."

        }

        if (row.complete === 1 && !textOnly && (row.sfa_review?.status !== "rejected" && row.sfa_review?.status !== "approved")) {
            if (userCan('review.sfa')) {
                return <button className="btn btn-block btn-primary" onClick={(e) => reviewSfaButton(row, e)} id={row.ID}>Review</button>
            }
        }
        else {
            return "Application hasn't been submitted"
        }
    }
    const onPageSizeChanged = (value, selectOptionSetter) => {
        selectOptionSetter(value)
        sfaGridRef.current.api.paginationSetPageSize(Number(value));
        empowerGridRef.current.api.paginationSetPageSize(Number(value));
    }
    const onSfaBtnExport = useCallback(() => {
        sfaGridRef.current.api.exportDataAsCsv();
    }, []);
    const onEmpowerBtnExport = useCallback(() => {
        empowerGridRef.current.api.exportDataAsCsv();
    }, []);
    const sideBar = useMemo(() => {
        return {
            toolPanels: ['columns'],
        };
    }, []);

    useEffect(() => {
        async function fetchData() {
            return await fetchApplications().then((res) => {
                return Promise.all(res).then(() => {
                    setLoading(false);
                })
            });
        }

        fetchData().then();
    }, [])

    return (
        <Main loading={loading} title={"Applications"}>
            {
                userCan('applications.sfa') &&
                <div className="row">
                    <div className="col-md-12 card">
                        <div className="card-header">
                            <div className="card-title">
                                SFA Applications
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row text-right">
                                <div className="col-md-8">
                                    {enableSfaApplication && userCan('apply.sfa') &&
                                        <button onClick={e => navigate('/sfa')}
                                                className="float-left btn btn-primary new-application-btn">Start a new SFA Application
                                        </button>
                                    }
                                </div>
                                <div className="col-md-2 form-group row">
                                    <label className="col-6 col-form-label">Page Size:</label>
                                    <select className="col-6 form-control page-size"
                                            onChange={e => onPageSizeChanged(e.target.value, setTablePageSize)}
                                            value={tablePageSize}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                        <option value="1000">1000</option>
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <button className="btn btn-block btn-outline-secondary" onClick={onSfaBtnExport}>Download CSV export
                                        file
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <AgGridReact
                                        ref={sfaGridRef}
                                        className="ag-theme-alpine grid_height_fix"
                                        rowData={sfaData}
                                        columnDefs={sfaColumnDefs}
                                        defaultColDef={sfaDefaultColDef}
                                        pagination={true}
                                        paginationPageSize={10}
                                        overlayNoRowsTemplate={'<span>There are no SFA Applications.</span>'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <br/>
            {
                userCan('applications.empower') &&
                <div className="row">
                    <div className="col-md-12 card">
                        <div className="card-header">
                            <div className="card-title">
                                Empower Applications
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row text-right">
                                <div className="col-md-8">
                                    {enableEmpowerApplication && userCan('apply.empower') &&
                                            <button onClick={e => navigate('/empower')}
                                                    className="float-left btn btn-primary new-application-btn">Start a new Empower
                                                Application
                                            </button>
                                    }
                                </div>
                                <div className="col-md-2 form-group row">
                                    <label className="col-6 col-form-label">Page Size:</label>
                                    <select
                                        className="col-6 form-control page-size"
                                        onChange={e => onPageSizeChanged(e.target.value, setTablePageSize)}
                                        value={tablePageSize}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                        <option value="1000">1000</option>
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <button className="btn btn-block btn-outline-secondary" onClick={onEmpowerBtnExport}>Download CSV export
                                        file
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <AgGridReact
                                        ref={empowerGridRef}
                                        className="ag-theme-alpine grid_height_fix"
                                        rowData={empowerData}
                                        columnDefs={empowerColumnDefs}
                                        defaultColDef={sfaDefaultColDef}
                                        pagination={true}
                                        paginationPageSize={10}
                                        overlayNoRowsTemplate={'<span>There are no Empower Applications.</span>'}
                                        sideBar={sideBar}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Main>
    );
}

export default Applications;