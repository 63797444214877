import React, {useEffect, useState} from 'react';
// import * as RNLocalize from "react-native-localize";
// import moment from "moment-timezone";
import {Controller, useForm} from "react-hook-form";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {DataGrid} from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import parse from "html-react-parser";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Datepicker from "../../../modules/wizard/components/datepicker/datepicker";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DialogActions from "@mui/material/DialogActions";
import {createTheme} from "@mui/material";
import {DeleteSVGIcon} from "react-md";

function DataTableForm(props) {
    let dataTableName = props.name;
    let onChangeFunc = props.onChange;
    let onDeleteFunc = props.onDelete;
    let saveEditButton = props.saveEditButton;
    let addButton = props.addButton;
    let field = props.field;
    let fields = props.field.fields;
    let dataTableValues = props.dataTableValues;
    let columns = props.columns;
    let label = props.label;
    const [openModal, setOpenModal] = useState(true)
    const [editingId, setEditingId] = useState(null);
    const {formState: { errors, isDirty, isValid }, handleSubmit, control, reset, watch, trigger, getValues, setValue} = useForm();
    const theme = createTheme({
        components: {
            MuiDialog:{
                root:{
                    styleOverrides:{
                        root:{
                            zIndex: '0'
                        }
                    }
                }
            }
        },
    });
    setActionButtons()

    function setActionButtons(){
        columns.map((column) => {
            if(column.field === "edit"){
                column['disableClickEventBubbling'] = true;
                column['renderCell'] = () => {
                    return (
                        <Button variant="outlined" color="primary" startIcon={<EditIcon />}>Edit</Button>
                    );
                }
                return;
            }
            if(column.field === "delete"){
                column['disableClickEventBubbling'] = true;
                column['renderCell'] = () => {
                    return (
                        <Button variant="outlined" color="error" startIcon={<DeleteIcon />}>Delete</Button>
                    );
                }
            }
        })

    }

    function handleChange(event){
        let value = event.target.value;
        let name = event.target.name;
        let type = event.target.type;
        if(type === "number" && value.startsWith("0")){
            value = value.substring(1);
        }
        if(type === "number" && value === ""){
            value = 0;
        }
        setValue(name,value)
    }

    function currentlySelected(params: GridCellParams) {
        const value = params.colDef.field;

        if (value === "edit") {
            let row =params.row;
            Object.entries(row).forEach(([key, value] )=> setValue(key, value))
            setEditingId(getValues().id)
            setOpenModal(true)
        }
        else if(value === "delete"){
            let row =params.row;
            Object.entries(row).forEach(([key, value] )=> setValue(key, value))
            onDeleteFunc(dataTableName, getValues().id)
        }
        else{
            return;
        }
    }

    function submitDataTable(){
        let dataTableFieldsValues = getValues()
        if(editingId !== null){
            onChangeFunc(dataTableName, dataTableFieldsValues, editingId)
            setEditingId(null);
        }
        else{
            dataTableFieldsValues['id'] = "";
            onChangeFunc(dataTableName, dataTableFieldsValues);
        }
        reset()
        setOpenModal(false)
    }

    function resetForm(){
        setEditingId(null);
        reset();
    }

    function handleModal(){
        setOpenModal(prevOpenModal=> {
            setOpenModal(!prevOpenModal);
            reset();
        });
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            trigger(name);
        });

        return () => {
            subscription.unsubscribe();
        }
    }, [watch, isValid, isDirty, trigger, setValue, getValues, errors]);
    return (
        <div style={{ display: 'flex', flexFlow: 'row wrap', height: '100%', justifyContent: 'flex-end', rowGap: '10px' }}>
            <div className="float-right" >
                <button className="btn btn-success m-0" type="button" onClick={handleModal}>{field.newButton}</button>
            </div>
            <div className="float-left" style={{ flex: '1 100%' }}>
                <DataGrid
                    autoHeight
                    rows={dataTableValues}
                    columns={columns}
                    onCellClick={currentlySelected}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    disableColumnMenu
                    rowHeight={50}
                    columnBuffer={8}
                />
            </div>
            <Dialog open={openModal} scroll="body" PaperProps={{
                sx: {
                    // width: "50%",
                    // maxHeight: 300,
                    minHeight: '80vh'
                }
            }}>
                <DialogTitle className="dialogTitle">
                    {label}
                    <IconButton className="float-right" onClick={handleModal} style={{cursor:'pointer', float:'right', marginTop: '-8px', width: '20px'}}>
                        <CloseIcon  />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(submitDataTable)}>
                        <Grid container>
                            {fields.map((field, index) => {
                                const type = field.type;
                                const format = field.format;
                                const label = field.label;
                                const name = field.name;
                                const error = field.error_text;
                                const required = field.required;
                                const section = field.section;
                                const width = field.width;
                                const textFieldRows = field.rows;
                                let size_sm = 6;
                                if(section || width){
                                    if(width){
                                        size_sm = width;
                                    }
                                    else{
                                        size_sm = 12;
                                    }
                                }
                                return(
                                    <Grid item xs={12} sm={size_sm} sx={{p:'10px'}} key={name}>
                                        {
                                            section !== undefined &&
                                            <React.Fragment>
                                                <Divider textAlign="left" sx={{fontWeight:"bold"}}>{section}</Divider>
                                            </React.Fragment>
                                        }
                                        {
                                            type === "checkbox" &&
                                            <React.Fragment>
                                                <FormControlLabel
                                                    key={name}
                                                    name={name}
                                                    control={
                                                        <Controller
                                                            control={control}
                                                            name={name}
                                                            defaultValue={false}
                                                            rules={{required: required}}
                                                            render={(
                                                                {
                                                                    field: {onChange, value, onBlur},
                                                                }) =>
                                                                <Checkbox
                                                                    onBlur={onBlur}
                                                                    onChange={onChange}
                                                                    checked={value}
                                                                    key={name}
                                                                    required={required}
                                                                    {...field}
                                                                />
                                                            }
                                                        />
                                                    }
                                                    label={parse(label)} />
                                                <FormHelperText sx={{fontSize: 14, fontWeight: "bold"}} className="text-danger">
                                                    {errors[name]?.type === 'required' && error}
                                                </FormHelperText>
                                            </React.Fragment>
                                        }
                                        {
                                            ((type === "text" && format === undefined) || (type === "number" && format === undefined)) &&
                                            <Controller
                                                control={control}
                                                name={name}
                                                defaultValue={type === "number" ? "0" : ""}
                                                rules={{required: required}}
                                                helperText={errors[name]?.type === 'required' ? error : ""}
                                                error={errors[name]?.type === 'required'}
                                                render={(
                                                    {
                                                        field: {onChange, value, ref, onBlur},
                                                    }) =>
                                                    <TextField
                                                        className="w-100"
                                                        name={name}
                                                        value={value}
                                                        onBlur={onBlur}
                                                        InputProps={{ inputProps: { min: 1 } }}
                                                        type={type}
                                                        onChange={event=>{
                                                            handleChange(event)
                                                        }}
                                                        multiline={textFieldRows > 1}
                                                        rows={textFieldRows ?? 1}
                                                        label={label}
                                                        required={required}
                                                    />
                                                }
                                            />
                                        }
                                        {
                                            type === "date" &&
                                            <Controller
                                                control={control}
                                                name={name}
                                                defaultValue={getValues(name)}
                                                rules={{required: required}}
                                                helperText={errors[name]?.type === 'required' ? error : ""}
                                                error={errors[name]?.type === 'required'}
                                                render={(
                                                    {
                                                        field: {onChange, value},
                                                    }) =>
                                                    <Datepicker onChange={onChange} label={label} value={value}/>
                                                }
                                            />
                                        }
                                        {
                                            type === "select" &&
                                            <FormControl fullWidth>
                                                <InputLabel required={required}>{label}</InputLabel>
                                                <Controller
                                                    control={control}
                                                    name={name}
                                                    defaultValue={""}
                                                    rules={{required: required}}
                                                    render={({
                                                                 field: {onChange, value,  onBlur},
                                                             }) =>
                                                        <Select
                                                            label={label}
                                                            name={name}
                                                            value={value}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                        >
                                                            {Object.entries(field.options).map(([key, value]) => {
                                                                return(
                                                                    <MenuItem
                                                                        style={{width: "100%", justifyContent: "flex-start", position: "relative", padding: "6px 16px"}}
                                                                        key={key}
                                                                        value={key}
                                                                    >
                                                                        {value}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    }
                                                />
                                            </FormControl>
                                        }
                                    </Grid>
                                )
                            })
                            }
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={resetForm}>Reset</Button>
                    <Button variant="contained" onClick={submitDataTable} autoFocus>{editingId !== null ? saveEditButton : addButton}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DataTableForm;
