import { useState, useEffect } from 'react';
import useToken from './useToken';

const usePermissions = () => {
    const [token] = useToken();

    const getPermissionsFromToken = token => {
        const encodedPayload = token.split('.')[1];
        return JSON.parse(atob(encodedPayload))[0].user_permissions[0].permissions;
    }

    const getRolesFromToken = token => {
        const encodedPayload = token.split('.')[1];
        return JSON.parse(atob(encodedPayload))[0].user_permissions;
    }

    const [permissions, setPermissions] = useState(() => {
        if(!token) return null;
        return getPermissionsFromToken(token)
    })
    const [roles, setRoles] = useState(() => {
        if(!token) return null;
        return getRolesFromToken(token)
    })

    useEffect(() =>{
        // console.log("I am updating user.")
        if(!token){
            setPermissions(null);
            setRoles(null);
        }
        else {
            setPermissions(getPermissionsFromToken(token));
            setRoles(getRolesFromToken(token))
        }
    }, [token]);

    const userCan = permission => {
        return  permissions.findIndex(e => e.slug === permission) > -1;
    }

    const userIs = role => {
        return  roles.findIndex(e => e.slug === role) > -1;
    }

    return [userCan, userIs];
}

export default usePermissions;