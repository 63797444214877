import {useParams, useLocation, useNavigate} from "react-router-dom";
import { VerifyEmailApi } from "../../services/api/verifyEmail";
import React, {useEffect, useState} from "react";

function VerifyEmail(props){
    const [message, setMessage] = useState('Please wait while we verify your account...');
    let { id } = useParams();
    let location = useLocation();
    let verify_url = decodeURIComponent(location.search.replace("?verify_url=", ""));
    let result = verify_url.match('\\?(.*)')
    let url = id+result[0];
    const navigate = useNavigate();
    useEffect(() => {
        VerifyEmailApi.getSingle(url)
        .then(res => {
            if(res.status === 401){
                setMessage(res.data.message)
            }
            else{
                setMessage(res.message)
                localStorage.removeItem('token')
                localStorage.setItem('verified', 'true')
                navigate('/login')
            }
        });
    }, [navigate, url])


    return (
        <div className="verify-page">
            <div className="verify-box">
                <div className="card">
                    <div className="card-header text-center">
                        <h5 className="card-title">{message}</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmail;