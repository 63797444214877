import ApiCore from "../utilities/core";

const url = 'auth/email/verify';

// plural and single may be used for message logic if needed in the ApiCore class.

export const VerifyEmailApi = new ApiCore({
    getAll: false,
    getSingle: true,
    post: false,
    put: false,
    patch: false,
    delete: false,
    url: url,
    requireToken: true
});
