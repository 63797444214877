import axios from 'axios';
import { handleResponse, handleError } from './response';
import jwtDecode from "jwt-decode";

// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally
const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = 'https://empower.icnarelief.org/backend/public/api';

const instance = axios.create({
    baseURL: {BASE_URL},
    headers: {
        "Content-Type": "application/json",
    },
});

/** @param {string} resource */
const getAll = (resource) => {
    return instance
        .get(`${BASE_URL}/${resource}`)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource
 *  @param {string} id */
const getSingle = (resource, id) => {
    return instance
        .get(`${BASE_URL}/${resource}/${id}`)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource
 * @param {object} model
**/
const post = (resource, model) => {
    return instance
        .post(`${BASE_URL}/${resource}`, model)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource
 * @param {string} id
 * @param {object} model
 **/
const put = (resource, id, model) => {
    // console.log(model)
    return instance
        // .put(`${BASE_URL}/${resource}/${id}`, model)
        .post(`${BASE_URL}/${resource}/${id}`, model)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource
 * @param {object} model
 * @param {string} id
 **/
const patch = (resource, model, id) => {
    return instance
        // .patch(`${BASE_URL}/${resource}/${id}`, model)
        .post(`${BASE_URL}/${resource}/${id}`, model)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource
 * @param {string} id
**/
const remove = (resource, id) => {
    return instance
        .delete(`${BASE_URL}/${resource}`, id)
        .then(handleResponse)
        .catch(handleError);
};

// Request interceptor
instance.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('token');
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
instance.interceptors.response.use(
    (res) => {
        let path = res.config.url.replace(BASE_URL, '');
        if(path !== '/auth/logout' && path !== '/auth/login' && path !== '/auth/refresh' && path !== '/auth/register'){
            let token = localStorage.getItem('token');
            let decodedToken = jwtDecode(token);
            let currentDate = new Date();
            // console.log(decodedToken.exp * 1000 - currentDate.getTime())
            if(decodedToken.exp * 1000 - currentDate.getTime() <= 1800000){
                axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
                axios.post(`${BASE_URL}/auth/refresh`).then(res=>{
                    window.localStorage.setItem("token", res.data.access_token);
                });
            }
        }
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (err.response) {
            let path = originalConfig.url.replace(BASE_URL, '');
            if(path === '/auth/login'){
                // console.log("I am at the login page.")
                return Promise.reject(err);
            }
            // Access Token was expired
            else if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    window.localStorage.removeItem("token");
                    return window.location.href = `/login`;
                } catch (_error) {
                    if (_error.response && _error.response.data) {
                        return Promise.reject(_error.response.data);
                    }
                    return Promise.reject(_error);
                }
            }
            else if (err.response.status === 403 && err.response.data) {
                return Promise.reject(err.response.data);
            }
        }
        return Promise.reject(err);
    }
);


export const apiProvider = {
    getAll,
    getSingle,
    post,
    put,
    patch,
    remove,
};