import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import ReactLoading from 'react-loading';
import {useForm} from "react-hook-form";
import { ForgotPasswordApi } from "../../services/api/forgot-password";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';


function ForgotPassword(props) {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(undefined);
    const [forgotPasswordMsg, setForgotPasswordMsg] = useState(undefined);
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        defaultValues: {
            // email: "qazi91@gmail.com",
        },
        mode: 'onChange'
    };
    const {register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isValid } = formState;
    // console.log(registeredMsg)

    const onResetPasswordClick = (formValues) =>{
        // display form data on success
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
        // return false;
        //     setErrorMsg("Register is not implemented yet");
        setForgotPasswordMsg(undefined)
        setErrorMsg(undefined)
        setLoading(true);

        // console.log(formValues)

        ForgotPasswordApi.post(formValues).then(res => {
            // console.log(res);
            // console.log(res.message);
            setLoading(false)
            if(res.status === 409){
                setErrorMsg(prev=> res.data.message)
            }
            // else{
            //     localStorage.setItem("token", res.access_token)
            //     setToken(res.access_token)
            // }
            // else{
            localStorage.setItem('forgotPassword', res.message);
            navigate('/login')
            // setRegisteredMsg(prev=> res.message)
            // }
            // reset()
        });
    }

    return (
        <div className="login-page">
            <div className={forgotPasswordMsg !== undefined ? "alert alert-success alert-dismissible": "d-none"}>
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                <h5><i className="icon fas fa-check"></i> Success!</h5>
                {forgotPasswordMsg}
            </div>

            <div className={errorMsg !== undefined ? "alert alert-danger alert-dismissible": "d-none"}>
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                <h5><i className="icon fas fa-check"></i> Error!</h5>
                {errorMsg}
            </div>
            <div className="login-box">
                <div className="card card-outline card-primary ">

                    <div className={loading ? "overlay" : "overlay d-none"}>
                        <ReactLoading type="spin" color="black" width={'10%'} height={'10%'} />
                    </div>

                    <div className="card-header text-center">
                        <Link to={"/forgot-password"} className="h1">{process.env.REACT_APP_NAME}</Link>
                    </div>
                    <div className="card-body">
                        <p className="register-box-msg">You forgot your password? Here you can easily reset your password.</p>
                        <form onSubmit={handleSubmit(onResetPasswordClick)}>
                            <div className="input-group mb-3">
                                <input name="email" type="text" {...register('email')} placeholder="Email Address" className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                                <div className="invalid-feedback">{errors.email?.message}</div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <button disabled={!isValid} type={"submit"} className="btn btn-primary btn-block">Reset password</button>
                                </div>
                                {/*/.col*/}
                            </div>
                        </form>

                        <p className="text-center mt-3 mb-0">
                            <Link to={"/login"} className="text-center">Login</Link>
                        </p>
                    </div>
                    {/*/.card-body*/}
                </div>
                {/*/.card*/}
            </div>
        </div>
    );
}

export default ForgotPassword;