import { createStore, combineReducers, applyMiddleware } from 'redux'
import * as reducers from './reducers'
import thunk from 'redux-thunk'


const rootReducer = combineReducers({
    dashboardValues: reducers.updateDashboardValues
})

//--> Setup
const initialState = {
    dashboardValues: {}
}

const store = createStore(rootReducer, initialState, applyMiddleware(thunk))
export default store;