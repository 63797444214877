import ApiCore from "../utilities/core";

const url = 'auth/login';
const plural = 'login';
const single = 'login';

// plural and single may be used for message logic if needed in the ApiCore class.

export const LoginApi = new ApiCore({
    post: true,
    url: url,
    plural: plural,
    single: single,
    requireToken: false
});