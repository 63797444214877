import React, {useState, useEffect} from 'react';
import DialogModule from "../../modules/dialog/dialog"
import {useNavigate } from "react-router-dom";
import { RefreshApi } from "../../services/api/refresh-token";

function IdleMonitor()
{
    //Modal
    const [idleModal, setIdleModal] = useState(false);
    const navigate = useNavigate();

    /**
     * Add any other events listeners here
     */
    const eventTypes = [
        'mousemove',
        'click',
        'keypress',
        'mousedown',
        'scroll',
        'touchmove',
        'pointermove'
    ];


    /**
     * @method extendSession
     * This function will extend current user session.
     */
    const extendSession = () =>
    {
        // console.log('user wants to stay logged in');
        RefreshApi.refresh();
    }


    /**
     * @method logOut
     * This function will destroy current user session.
     */
    const logOut = () =>
    {
        navigate('/logout')
    }

    useEffect(() => {
        const createTimeout1 = () => setTimeout(()=>{
            setIdleModal(true);
        },900000)
        // 900000

        const createTimeout2 = () => setTimeout(() => {
            // Implement a sign out function here
            logOut()
        },60000)

        const listener = () => {
            if(!idleModal){
                clearTimeout(timeout)
                timeout = createTimeout1();
            }
        }

        const addEventListeners = (listener) => {
            eventTypes.forEach((type) => {
                window.addEventListener(type, listener, false)
            })
        }

        const removeEventListeners = (listener) => {
            if (listener) {
                eventTypes.forEach((type) => {
                    window.removeEventListener(type, listener, false)
                })
            }
        }

        let timeout = idleModal  ? createTimeout2() : createTimeout1()
        addEventListeners(listener);

        // Cleanup
        return () => {
            removeEventListeners(listener);
            clearTimeout(timeout);
        }
    },[idleModal])


    return (
        <div>
            <DialogModule
                open={idleModal}
                title={"Are you still there?"}
                setOpen={setIdleModal}
                onConfirm={extendSession}
                onDecline={logOut}
                yesButton={"Stay Logged In"}
                noButton={"Log Off"}
            >
                You're being timed out due to inactivity. Please choose to stay signed in or to logoff. Otherwise, you will be logged off automatically.
            </DialogModule>
        </div>
    )

}

export default IdleMonitor;