import React, {useEffect} from "react"
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom"
import useAuth from "./useAuth";
import useUser from "./useUser";

const PrivateRoute = (props) => {
    const auth = useAuth();
    let location = useLocation();
    let navigate = useNavigate();
    const pathname = location.pathname;
    const user = useUser();
    const user_verified = user?.email_verified_at;

    useEffect(() => {
        if(user !== null && !user_verified){
            navigate('/verify-account')
        }
    }, [user_verified, navigate, user])

    return auth ? <Outlet /> : <Navigate to={"/login?redirect_url=" + pathname}/>
}

export default PrivateRoute;