import React from "react";
import {DateTime} from 'luxon';

const Footer = () => {

    return (
        <footer className="main-footer">
            <strong>
                <span>Copyright © {DateTime.now().toFormat('y')} </span>
                <a
                    href="https://www.icnarelief.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    ICNARelief.org
                </a>
            </strong>
            <div className="float-right d-none d-sm-inline-block">
                <span>&nbsp;{process.env.REACT_APP_VERSION}</span>
            </div>
        </footer>
    )
}

export default Footer;