import ApiCore from "../utilities/core";

const url = 'auth/refresh';
const plural = 'refresh';
const single = 'refresh';

// plural and single may be used for message logic if needed in the ApiCore class.

export const RefreshApi = new ApiCore({
    post: true,
    url: url,
    plural: plural,
    single: single,
    requireToken: true
});

RefreshApi.refresh = () =>{

    RefreshApi.post().then((res) => {
        //console.log(res)
        window.localStorage.setItem("token", res.access_token);
    })

}