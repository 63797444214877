import ApiCore from "../utilities/core";

let url = 'sfa-review';
const plural = 'applications';
const single = 'application';

// plural and single may be used for message logic if needed in the ApiCore class.

export const apiSfaReview = new ApiCore({
    getAll: true,
    getSingle: true,
    post: true,
    put: true,
    patch: true,
    delete: false,
    url: url,
    plural: plural,
    single: single,
    requireToken: true
});
