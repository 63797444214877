import ApiCore from "../utilities/core";

const url = 'auth/forgot-password';
const plural = 'forgot-password';
const single = 'forgot-password';

// plural and single may be used for message logic if needed in the ApiCore class.

export const ForgotPasswordApi = new ApiCore({
    post: true,
    url: url,
    plural: plural,
    single: single,
    requireToken: false
});
