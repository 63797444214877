import React, {useEffect, useRef, useState, useMemo, useCallback} from 'react';
import Main from "../../modules/main/main";
import usePermissions from "../../services/auth/usePermissions";
import {useNavigate} from "react-router-dom";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {apiUsers} from "../../services/api/users"; // Optional theme CSS
import {capitalizeFirst} from "../../services/common";

function Users(props) {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [userCan] = usePermissions();
    const breadcrumbs = [
        {"link": "/", "name": "Users", "active": true}
    ];

    const gridRef = useRef(); // Optional - for accessing Grid's API
    const [rowData, setRowData] = useState([]);

    const [columnDefs] = useState([
        {
            field: 'name'
        },
        {
            field: 'email'
        },
        {
            headerName: 'Type (Role)',
            valueFormatter: params => {
                return capitalizeFirst(params.data.roles[0].name);
            },
            filter: false
        },
        {
            cellRenderer: params => {
                if(userCan('edit.users')) {
                    return (
                        <span>
                        <button className={'btn btn-primary mb-2'} onClick={() => navigate('/users/'+params.data.id)}>Edit</button>
                    </span>
                    );
                }

            },
            editable: false,
            filter: false,
            minWidth: 100,
            maxWidth: 100,
        }
    ]);

    const defaultColDef = useMemo( ()=> ({
        sortable: true,
        resizable: true,
        filter: true,
        floatingFilter: true,
        suppressMenu: true
        // filter: 'agTextColumnFilter',
    }));

    const fetchUsers = async (page, perPage) => {
        apiUsers.getAll().then(res=>{
            setRowData(res.users);
            setLoading(false)
        })
    }

    const gridOptions = {
        // PROPERTIES
        // Objects like myRowData and myColDefs would be created in your application
        rowData: rowData,
        columnDefs: columnDefs,
        pagination: true,
        rowSelection: 'single',

        // EVENTS
        // Add event handlers
        // onRowClicked: event => console.log('A row was clicked'),
        onColumnResized: event => event.api.sizeColumnsToFit(),
        onGridReady: event => {
            event.api.sizeColumnsToFit();
            event.columnApi.resetColumnState();
        },

        // CALLBACKS
        getRowHeight: (params) => 25
    }

    useEffect(() => {
        if(!userCan('view.users')){
            navigate('/');
        }
        fetchUsers()
    }, [])

    return (
        <Main title="Users" breadcrumbs={breadcrumbs} loading={loading}>
            <div className="ag-theme-alpine" style={{ height: 800 }}>
                <AgGridReact
                    style={{ width: '100%', height: '100%;' }}
                    ref={gridRef} // Ref for accessing Grid's API

                    rowData={rowData} // Row Data for Rows

                    // columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    gridOptions={gridOptions}
                    getRowHeight={1000}
                    // animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    // rowSelection='multiple' // Options - allows click selection of rows

                    // onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                ></AgGridReact>
            </div>
        </Main>
    );
}

export default Users;