import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';
import useUser from "../../services/auth/useUser";
import Main from "../../modules/main/main";
import DashboardApi from "../../services/api/dashboard";

function Dashboard() {
    const [loading, setLoading] = useState(true);
    const [dashboardValues, setDashboardValues] = useState();
    const user = useUser();
    const [parentView, setParentView] = useState(false);
    const navigate = useNavigate();

    const fetchDashboardCharts = async () => {
        DashboardApi.getAll().then((data)=>{
            setDashboardValues(data.bar_chart_data)
            setLoading(false)
        })
    }

    let breadcrumbs = [
        {"link": "/", "name": "Dashboard", "active": false}
    ];

    useEffect(()=>{
        if(!parentView){
            setLoading(true)
            fetchDashboardCharts();
        }
        else{
            setLoading(false)
        }
    }, [parentView])

    useEffect(() => {
        if(user.user_permissions[0].slug === 'parent' || user.user_permissions[0].slug === 'teacher'){
            navigate('/applications')
        }
    }, [])

    return (
        <Main title="Dashboard" breadcrumbs={breadcrumbs} loading={loading}>
            {/*<div className="row">*/}
            {/*    <div className="ml-auto">*/}
            {/*        <label>*/}
            {/*            <span>Admin View</span>*/}
            {/*            <Toggle*/}
            {/*                defaultChecked={parentView}*/}
            {/*                icons={false}*/}
            {/*                onChange={() => setParentView(!parentView)} />*/}
            {/*            <span>Parent View</span>*/}
            {/*        </label>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={parentView ? "row" : "row d-none"}>
                <div className="col-md-3 col-sm-6 col-12">
                    <div className="info-box bg-warning">

                        <div className="info-box-content">
                            <span className="info-box-text">Application Status</span>

                            <div className="progress">
                                <div className="progress-bar" style={{width: '70%'}}/>
                            </div>
                            <span className="progress-description">You are 70% Done.</span>
                            <div className="ml-auto text-bold">
                                <Link className="text-white text-uppercase" to="/sfa">Complete
                                    Application <i className="fas fa-arrow-right"/></Link>
                            </div>
                        </div>
                        {/* /.info-box-content */}
                    </div>
                    {/*/.info-box*/}
                </div>
            </div>
            {/* /.row */}
            <div className={parentView ? "row d-none" : "row"}>
                <div className="col-lg-6">
                    <div className="card">
                        <div className={loading ? "overlay" : "overlay d-none"}>
                            <ReactLoading type="spin" color="black" width={'10%'} height={'10%'} />
                        </div>
                        <div className="card-header border-0">
                            <div className="d-flex justify-content-between">
                                <h3 className="card-title">Applications Approved and Paid Out</h3>
                                <Link to="/">View Report</Link>
                            </div>
                        </div>
                        <div className="card-body">
                        </div>
                    </div>
                </div>
                {/* /.col-md-6 */}
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title m-0">Featured</h5>
                        </div>
                        <div className="card-body">
                            <h6 className="card-title">Special title treatment</h6>
                            <p className="card-text">With supporting text below as a natural lead-in to additional
                                content.</p>
                            <Link to="/" className="btn bg-green">Go somewhere</Link>
                        </div>
                    </div>
                </div>
                {/* /.col-md-6 */}
            </div>
        </Main>
    )
}

export default Dashboard;