import React from "react";
import Header from "./header/header";
import Footer from "./footer/footer";
import {Link} from "react-router-dom";
import Sidebar from "./sidebar/sidebar";
import './main.scss'
import ReactLoading from "react-loading";
import IdleMonitor from "../idle-monitor/IdleMonitor";

function Main({title, smallTitle="", breadcrumbs=[], loading=false, children}) {
    return (
        <>
            <Header />
             {/*Content Wrapper. Contains page content*/}
            <Sidebar/>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0" id="title">{title} <small>{smallTitle}</small></h1>
                            </div>
                            {/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    {breadcrumbs.map((anObjectMapped, index) => {
                                        return (
                                            <li key={index} className={anObjectMapped.active ? "breadcrumb-item active" : "breadcrumb-item"}>
                                                { anObjectMapped.link ?
                                                    <Link to={anObjectMapped.link}>{anObjectMapped.name}</Link>
                                                    :
                                                    <span>{anObjectMapped.name}</span>
                                                }
                                            </li>
                                        )
                                    })}
                                </ol>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <div className="content">
                    <div className="container-fluid">
                        {/*{loading ? (*/}
                            <>
                                <ReactLoading className={loading ? "loader" : "d-none"} type="spin" color="orange" width={'5%'} height={'5%'}/>
                                { !loading && children }
                                <IdleMonitor/>
                            </>
                            {/*) :*/}
                            {/*(children)*/}
                        {/*}*/}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <Footer/>
        </>
    )
}

export default Main;