import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

function TimelineTab({isActive, audits, user_name}) {
    audits = audits.sort(function(a,b){
        return new Date(b.created_at) - new Date(a.created_at);
    });
    return (
        <div className={`tab-pane ${isActive ? 'active' : ''}`}>
            {/* The timeline */}
            <div className="timeline">
                {
                    audits.map((audit) => {
                        if(audit.event === "Logged In") {
                            return (
                                <div key={audit.id}>
                                    <i className="fas fa-unlock bg-success" />
                                    <div className="timeline-item">
                                        <span className="time">
                                          <i className="far fa-clock" />
                                            <span> <Moment fromNow>{audit.created_at}</Moment></span>
                                        </span>
                                        <h3 className="timeline-header">
                                            {user_name}
                                            <span> logged in</span>
                                        </h3>
                                    </div>
                                </div>
                            )
                        }

                        if(audit.event === "Logged Out") {
                            return (
                                <div key={audit.id}>
                                    <i className="fas fa-lock bg-danger" />
                                    <div className="timeline-item">
                                        <span className="time">
                                          <i className="far fa-clock" />
                                          <span> <Moment fromNow>{audit.created_at}</Moment></span>
                                        </span>
                                        <h3 className="timeline-header">
                                            {user_name}
                                            <span> logged out</span>
                                        </h3>
                                    </div>
                                </div>
                            )
                        }

                        if(audit.event === "created" && audit.auditable_type === "App\\Models\\Sfa") {
                            return (
                                <div key={audit.id}>
                                    <i className="fas fa-file-alt bg-primary" />
                                    <div className="timeline-item">
                                        <span className="time">
                                          <i className="far fa-clock" />
                                          <span> <Moment fromNow>{audit.created_at}</Moment></span>
                                        </span>
                                        <h3 className="timeline-header">
                                            {user_name}
                                            <span> start a new SFA Application</span>
                                        </h3>
                                    </div>
                                </div>
                            )
                        }

                        if(audit.event === "created" && audit.auditable_type === "App\\Models\\Empower") {
                            return (
                                <div key={audit.id}>
                                    <i className="nav-icon fas fa-file-alt bg-warning" />
                                    <div className="timeline-item">
                                        <span className="time">
                                          <i className="far fa-clock" />
                                          <span> <Moment fromNow>{audit.created_at}</Moment></span>
                                        </span>
                                        <h3 className="timeline-header">
                                            {user_name}
                                            <span> start a new Empower Application</span>
                                        </h3>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
                <div>
                    <i className="far fa-clock bg-gray" />
                </div>
            </div>
        </div>
    );
}

export default TimelineTab;