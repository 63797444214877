import ApiCore from "../utilities/core";

const url = 'auth/register';
const plural = 'register';
const single = 'register';

// plural and single may be used for message logic if needed in the ApiCore class.

export const RegisterApi = new ApiCore({
    post: true,
    url: url,
    plural: plural,
    single: single,
    requireToken: false
});
