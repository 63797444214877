import { ResendVerificationEmailApi } from "../../services/api/resendVerificationEmail";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

function VerifyAccount(props){
    const navigate = useNavigate();
    const [resendMsg, setResendMsg] = useState(undefined);
    const resendEmail = () =>{
        ResendVerificationEmailApi
            .getAll()
            .then(res => {
                if(res.status === 401){
                    navigate('/login');
                }
                else{
                    setResendMsg(res.message)
                }
            })
    }

    return (
        <div className="verify-page">
            <div className="verify-box">
                <div className="row d-flex flex-column align-content-center">
                    <div className={resendMsg !== undefined ? "row": "d-none"}>
                        <div className="col-12 pt-3">
                            <div className="alert alert-success">
                                <h5><i className="icon fas fa-check"/> Success!</h5>
                                {resendMsg}
                            </div>
                        </div>
                    </div>
                    <div className="card col-6 pl-0 pr-0">
                        <div className="card-header d-flex justify-content-center">
                            <div className="row">
                                <div className="col-12">
                                    <h2>Please Verify Your Email Address</h2>
                                </div>
                            </div>
                        </div>
                        <div className="card-body text-center">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="text-warning"><i className="fa-solid fa-triangle-exclamation" /></h1>
                                    <h4>We noticed your email address has not been verified. By doing so, you will receive important notifications and information about your applications.</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <div className="row pt-1">
                                <div className="col-12">
                                    <button onClick={resendEmail} className="btn btn-primary">Resend Verification Email</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyAccount;