import React, {useEffect, useRef, useState, useMemo, useCallback} from 'react';
import Main from "../../modules/main/main";
import usePermissions from "../../services/auth/usePermissions";
import {useNavigate, useParams} from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {apiUsers} from "../../services/api/users";
import {capitalizeFirst, formatLocalDate} from "../../services/common";
import TimelineTab from './tabs/timeline-tab';
import Button from "@mui/material/Button";


function User(props) {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { id } = useParams();
    const [userCan] = usePermissions();
    const [rowData, setRowData] = useState([]);
    const [title, setTitle] = useState('');
    const [userRole, setUserRole] = useState('');
    const [activeTab, setActiveTab] = useState('TIMELINE');

    const breadcrumbs = [
        {"link": "/users", "name": "Users", "active": false},
        {"name": rowData['name'] !== undefined ? rowData['name'] : '', "active": true}
    ];
    const {register, formState: { errors, isValid }, handleSubmit, reset, watch, control,
        trigger, getValues, setValue, setError } = useForm({defaultValues: rowData});

    const fetchUser = async () => {
        apiUsers.getSingle(id).then(res=>{
            setRowData(res.user);
            setTitle("Profile")
            setLoading(false)
        })
    }

    const toggle = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        if(!userCan('view.users')){
            navigate('/');
        }
        fetchUser()
    }, [])

    useEffect(() => {
        if(Object.keys(rowData).length > 0){
            setUserRole(capitalizeFirst(rowData['roles'][0].name))
        }
    }, [rowData])

    return (
        <Main title={title} breadcrumbs={breadcrumbs} loading={loading}>
            <div className="row">
                <div className="col-md-3">

                    <div className="card card-primary card-outline">
                        <div className="card-body box-profile">

                            <h3 className="profile-username text-center">{rowData['name']}</h3>

                            <div className="text-muted text-center">Type (Role): {userRole}</div>

                            <ul className="list-group list-group-unbordered mb-3">
                                <li className="list-group-item">
                                    <b>SFA Applications</b> <a className="float-right">{rowData['sfas']?.length }</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Empower Applications</b> <a className="float-right">{rowData['empowers']?.length}</a>
                                </li>
                                <li className="list-group-item">
                                    <b>Joined</b> <a className="float-right">{formatLocalDate(rowData['created_at'])}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="card">
                        <div className="card-header p-2">
                            <ul className="nav nav-pills">
                                <li className="nav-item mr-1">
                                    <Button
                                        type="button"
                                        disabled={!isValid}
                                        className={`nav-link ${
                                            activeTab === 'TIMELINE' ? 'active' : ''
                                        }`}
                                        onClick={() => toggle('TIMELINE')}
                                    >
                                        Timeline
                                    </Button>
                                </li>
                                <li className="nav-item mr-1">
                                    <Button
                                        type="button"
                                        disabled={!isValid}
                                        className={`nav-link ${
                                            activeTab === 'SETTINGS' ? 'active' : ''
                                        }`}
                                        onClick={() => toggle('SETTINGS')}
                                    >
                                        Settings
                                    </Button>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">
                                <TimelineTab isActive={activeTab === 'TIMELINE'} audits={rowData['audit']} user_name={rowData['name']} />
                                {/*<SettingsTab isActive={activeTab === 'SETTINGS'} />*/}
                            </div>

                                {/*<div className="tab-content">*/}
                                {/*<TabPanel value={tabValue} index={0}>*/}

                                {/*/!*    <div className="form-group row">*!/*/}
                                {/*/!*        <label htmlFor="inputEmail"*!/*/}
                                {/*/!*               className="col-sm-2 col-form-label">Email</label>*!/*/}
                                {/*/!*        <div className="col-sm-10">*!/*/}
                                {/*/!*            <input*!/*/}
                                {/*/!*                type="email"*!/*/}
                                {/*/!*                className="form-control"*!/*/}
                                {/*/!*                id="email"*!/*/}
                                {/*/!*                placeholder="Email"*!/*/}
                                {/*/!*                value={getValues('email')}*!/*/}
                                {/*/!*                {...register('email', {required: true})}*!/*/}
                                {/*/!*            />*!/*/}
                                {/*/!*        </div>*!/*/}
                                {/*/!*    </div>*!/*/}
                                {/*/!*    <div className="form-group row">*!/*/}
                                {/*/!*        <label htmlFor="inputExperience"*!/*/}
                                {/*/!*               className="col-sm-2 col-form-label">Experience</label>*!/*/}
                                {/*/!*        <div className="col-sm-10">*!/*/}
                                {/*/!*                <textarea className="form-control" id="inputExperience"*!/*/}
                                {/*/!*                          placeholder="Experience"></textarea>*!/*/}
                                {/*/!*        </div>*!/*/}
                                {/*/!*    </div>*!/*/}
                                {/*/!*    <div className="form-group row">*!/*/}
                                {/*/!*        <label htmlFor="inputSkills"*!/*/}
                                {/*/!*               className="col-sm-2 col-form-label">Skills</label>*!/*/}
                                {/*/!*        <div className="col-sm-10">*!/*/}
                                {/*/!*            <input type="text" className="form-control" id="inputSkills"*!/*/}
                                {/*/!*                   placeholder="Skills" />*!/*/}
                                {/*/!*        </div>*!/*/}
                                {/*/!*    </div>*!/*/}
                                {/*/!*    <div className="form-group row">*!/*/}
                                {/*/!*        <div className="offset-sm-2 col-sm-10">*!/*/}
                                {/*/!*            <div className="checkbox">*!/*/}
                                {/*/!*                <label>*!/*/}
                                {/*/!*                    <input type="checkbox" /> I agree to the <a href="#">terms and*!/*/}
                                {/*/!*                    conditions</a>*!/*/}
                                {/*/!*                </label>*!/*/}
                                {/*/!*            </div>*!/*/}
                                {/*/!*        </div>*!/*/}
                                {/*/!*    </div>*!/*/}
                                {/*/!*    <div className="form-group row">*!/*/}
                                {/*/!*        <div className="offset-sm-2 col-sm-10">*!/*/}
                                {/*/!*            <button type="submit" className="btn btn-danger">Submit</button>*!/*/}
                                {/*/!*        </div>*!/*/}
                                {/*/!*    </div>*!/*/}
                                {/*/!*</TabPanel>*!/*/}
                                {/*/!*<TabPanel value={tabValue} index={1}>*!/*/}
                                {/*/!*    Item Two*!/*/}
                                {/*</TabPanel>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    );
}

export default User;